import $ from 'jquery';
let scrollTop = 0;
let $window = $(window);
let currentDir = null;
let isScrolled = false;
let threshold = 60;
let timeStamp = new Date();
timeStamp = timeStamp.getTime();

let watch = (e, init)=>{
	let current = $window.scrollTop();
	if (e && $('body').hasClass('no-scroll')){
		e.preventDefault();
	}
	if (init || (current>threshold && !isScrolled) || (current===0) ){
		isScrolled = current>threshold ? true: false;
		$('body').attr('data-scrolled', isScrolled)
	}
	
	if (Math.abs(current-scrollTop) < 61){
		return;
	}
	
	let dir = current > scrollTop ? 'down': 'up';
	if (Math.abs(current-scrollTop) < 5){
		return;
	}
	timeStamp = new Date();
	$('body').addClass('is-scrolling');
	setTimeout(function(){
		let now = new Date();
		if (now.getTime() >= timeStamp.getTime()+1500){
			return;
		}
		$('body').removeClass('is-scrolling');
	}, 1500);
	if (dir!== currentDir ){
		$('body').attr('data-scroll-dir', dir);
		currentDir = dir;
	}

	scrollTop = current;
}

$(document).ready(()=>watch(null, true))
$window.scroll(watch);