import $ from 'jquery';
$(document).ready(function(){
    $('.wp-block-bootstrap-accordion[data-template="big"').each(function(){	
        let currentDiv = $(this).hasClass('is-style-big-reverse');
        if (currentDiv){
            $(this).prev().addClass('is-style-big-height');
            $(this).next().addClass('is-style-big-height');          
        }
    });

})