import $ from 'jquery';
let adapt = function(){
	$('h1, h2, h3').each(function(){
		if ($(this).width() > $(window).width() - 30){
			let hw = $(this).width();
			let ww = $(window).width();
			let fs = (parseFloat($(this).css('fontSize').replace('px', '')));
			let ls = (parseFloat($(this).css('letterSpacing')));

			if (!$(this).attr('data-title-adapted')){
				$(this).attr('data-title-adapted', fs+'-'+ls);
			}
			$(this).css('fontSize', Math.round(fs * (ww-20)/hw,1))
			.css('letterSpacing', Math.round(ls*(ww-20)/hw,1));
		}
	})
}

$(function(){
	$(window).on('resize', function(){
		$('[data-title-adapted]').each(function(){
			let [fs, ls] = $(this).data('titleAdapted').split('-').map(x=>Math.round(parseFloat(x)));
			$(this).css('letterSpacing', ls).css('fontSize',fs);
		})
		adapt();
	})
	adapt();
});