import $ from 'jquery';

const getBg = ($el) =>{
	let bg = $el.css('backgroundColor');

	if (bg && bg!='rgba(0, 0, 0, 0)'){
		return bg;
	}
	if ($el.parents('body').length){
		return getBg($el.parent());
	}
	return 'white';
}

const init = ($scope)=>{
	$scope.find('.block-has-triangle').each(function(){
		let $el = $(this);
		if (!$el.children('.--triangle').length){
			const color = getBg($el.next());
			$el.append(`<div class="--triangle" style="color: ${color}">
				<div class="--triangle-part"></div>
				<div class="--triangle-part"></div>
				</div>
				`);
		}
	});
}
$(document).ready(function(){
	init($('body'))
})