import $ from 'jquery';

$.fn.typeEraseAnimation = function(){
	let text = $(this).text();
	let current = 0;
	$(this).attr('data-animation', 'type-erase')
	let $container = $(this);
	let spell = function(){
		let html = '<span>'+text.substring(0,current)+'</span><span style="opacity:0">'+text.substring(current)+'</span>';
		$container.html(html);
		if (current < text.length){
			setTimeout(spell, 50);
		}
		else{
			setTimeout(erase, 2000);	
		}
		current++;
	}
	let erase = function(){
		let html = '<span>'+text.substring(0,current)+'</span><span style="opacity:0">'+text.substring(current)+'</span>';
		$container.html(html);
		if (current > 0){
			setTimeout(erase, 20);
		}
		else{
			setTimeout(spell, 2000);	
		}
		current--;
	}
	spell();
}

$(function(){
	$('[data-animation="type-erase"], .is-style-animation-spell').each(function(){
		$(this).typeEraseAnimation()
	})
})