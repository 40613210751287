import $ from 'jquery';
const TOGGLE_SHOW_MENU_SEL = '.js-toggle-menu a[data-target]';
$(document).ready(function(){
	let hrefToggle = ()=>{
		let hrefs = $(TOGGLE_SHOW_MENU_SEL).attr('data-target');
		hrefs.split(',').map(href=>{
	
			if (!href || !$(href).length){
				console.error('button-js-action: invalid target for js action: '+href);
				return;
			}
			$(href).toggle();
	
		})
	}
	$(document).on('click', TOGGLE_SHOW_MENU_SEL, function(e){
		e.preventDefault();
		hrefToggle();
		if ($('#menu-mobile').is(":visible")){
			$('body').addClass('no-scroll')
		}
		else{
			$('body').removeClass('no-scroll')

		}

		$(".nav-link").click(function(){
			if (!$('body').hasClass('no-scroll')){
				$('body').addClass('no-scroll')
				hrefToggle();			
			}
			$('body').removeClass('no-scroll')
			hrefToggle();
		});
	})
})