import $ from 'jquery';

$.fn.lazyLoad = function(){
	let src = $(this).data('src');
	$(this).css('backgroundImage', 'url('+src+')')
}

$(function(){
	$('.lazy[data-src]').each(function(){
		$(this).lazyLoad();
	})
})