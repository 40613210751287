import $ from 'jquery';

$(document).on('click hover', 'a[href]', function(e){
	let href = $(this).attr('href');
	href = href.split('#');
	if (href[1] && $('#'+href[1]).offset().top){

		$('html, body').animate({
			scrollTop: $('#'+href[1]).offset().top - 160
		}, 100);
	}
});
