import './index.scss';
import './modules/scroll-watcher';
import './modules/title-decoration';
import './modules/block-decoration';
import './modules/tooltip';
import './modules/title-size-adapter';
import './modules/lazy-load'
import './modules/nav-responsive';
import './modules/spell-animation.js';
import './modules/button-js-action';
import './modules/accordion';
import './fix/video';
import './fix/anchor-position';



