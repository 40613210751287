import $ from "jquery";
import "./title-decoration.scss"
const TRIANGLE_DECORATION_SELECTOR = '.page-with-decoration .has-triangle-decoration';
const MENU_DECORATION_SELECTOR = '.page-with-decoration .is-style-menu-country .nav-link-level-0';
const TRIANGLE_DECORATION_RIGHT_SELECTOR = '.page-with-decoration .has-triangle-decoration-right';
const TRIANGLE_DECORATION_LEFT_SELECTOR = '.page-with-decoration .has-triangle-decoration-left';
const TRIANGLE_TITLE_DECORATION_STYLE = '.page-with-decoration .is-style-section-title-decoration';
const PAGE_WITH_DECORATION = '.page-with-decoration';
$(function(){
    if ($(PAGE_WITH_DECORATION).is('*')) {
        // $(PAGE_WITH_DECORATION).removeClass("page-with-decoration");
        $('body').addClass("page-with-decoration");
    }

    
    $(TRIANGLE_DECORATION_SELECTOR).each(function(){
        let lh = $(this).css('lineHeight');
        let h = Math.floor(parseFloat(lh)/2);
        let w  = Math.floor(h * 60 / 43);
        let icon = `<span style="align-self:center;vertical-align:middle;position:relative;display:inline-block;width:0px;height:0px;">
        <svg aria-hidden="true" style="position:absolute;top:0%;left:0px;width: ${w}px; height: ${h}px; transform: translate(-75%,-150%) rotate(-50deg);" class="title-decoration-triangles">
            <polygon fill="#FFBA00" points="5.08177891 11.336276 0.195453035 40.2633252 23.4543642 30.8815795"></polygon>
            <polygon fill="#E00000" points="30.6861265 10.94537 51.7950542 0 41.2405904 21.4998338"></polygon>
            <polygon fill="#0093D6" points="46.7132753 31.0770325 54.9223028 42.0224025 57.2677392 28.5361431"></polygon>
        </svg></span>`;
        $(this).prepend(icon);
    });
    $(MENU_DECORATION_SELECTOR).each(function(){
        let icon2 = `<span style="align-self:center;vertical-align:middle;position:relative;display:inline-block;width:0px;height:0px;" class="title-decoration-fleche">
        <svg aria-hidden="true" style="position:absolute;top:0%;left:0px;width: 1.5rem; height: 1.5rem; transform: translate(-65%,-85%) rotate(0deg);" class="title-decoration-triangles">
        <use xlink:href="/wp-content/themes/webfactory/assets/images/icons.svg#fleche-3"></use>
        </svg></span>`;
        $(this).prepend(icon2);
    });
    $(TRIANGLE_DECORATION_RIGHT_SELECTOR).each(function(){
        let lh = $(this).css('lineHeight');
        let h = Math.floor(parseFloat(lh)/1.5);
        let w  = Math.floor(h * 58 / 43);
        let icon = `<span style="align-self:center;vertical-align:middle;position:relative;display:inline-block;width:0px;height:0px;">
        <svg aria-hidden="true" style="position:absolute;top:0%;left:0px;width: ${w}px; height: ${h}px; transform: translate(-75%,-150%) rotate(33deg);" class="title-decoration-triangles">
            <polygon fill="#FFBA00" points="5.08177891 11.336276 0.195453035 40.2633252 23.4543642 30.8815795"></polygon>
            <polygon fill="#E00000" points="30.6861265 10.94537 51.7950542 0 41.2405904 21.4998338"></polygon>
            <polygon fill="#0093D6" points="46.7132753 31.0770325 54.9223028 42.0224025 57.2677392 28.5361431"></polygon>
        </svg></span>`;
        $(this).append(icon);
    });
    $(TRIANGLE_DECORATION_LEFT_SELECTOR).each(function(){
        let lh = $(this).css('lineHeight');
        let h = Math.floor(parseFloat(lh)/1.35);
        let w  = Math.floor(h * 58 / 43);
        let icon = `<span style="align-self:center;vertical-align:middle;position:relative;display:inline-block;width:0px;height:0px;">
        <svg aria-hidden="true" style="position:absolute;top:0%;left:0px;width: ${w}px; height: ${h}px; transform: translate(-75%,-140%) rotate(-50deg);" class="title-decoration-triangles">
            <polygon fill="#FFBA00" points="5.08177891 11.336276 0.195453035 40.2633252 23.4543642 30.8815795"></polygon>
            <polygon fill="#E00000" points="30.6861265 10.94537 51.7950542 0 41.2405904 21.4998338"></polygon>
            <polygon fill="#0093D6" points="46.7132753 31.0770325 54.9223028 42.0224025 57.2677392 28.5361431"></polygon>
        </svg></span>`;
        $(this).prepend(icon);
    });
})
