import $ from 'jquery';
$(document).ready(function(){
	let $tooltipHolder = null;
	let $tooltip = null;
	let hideTooltip = ()=>{
		if ($tooltipHolder && $tooltip){
			$tooltip.removeAttr('style')
			$tooltip.appendTo($tooltipHolder);
		}
		$tooltip = null;
		$tooltipHolder = null;
	}
	$('body').on('mouseenter', '.has-tooltip', function(e){
		hideTooltip();
		let scrollTop = $(window).scrollTop();
		$tooltipHolder = $(this);
		$tooltip = $(this).find('[role="tooltip"]');
		let rect = this.getBoundingClientRect();
		$tooltip.appendTo($('body'));
		const css = {
			position: 'absolute',
			top: rect.top+scrollTop+rect.height,
			left: rect.left+rect.width/2,
			transform: 'translateX(-50%)'
		}
		$tooltip.css(css);
		console.log(css);
	})
})